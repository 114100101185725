var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "preview-page",
      attrs: {
        id: "preview-page",
        centered: "",
        "hide-footer": "",
        "header-class": "p-0 d-flex align-items-center justify-content-between",
        "ok-only": "",
        "no-close-on-backdrop": ""
      },
      on: { hide: _vm.closePreviewModal },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function({ ok }) {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "div",
                    { staticStyle: { "background-color": "#53575b" } },
                    [
                      _c("img", {
                        staticClass: "m-3",
                        staticStyle: { width: "33px" },
                        attrs: {
                          src: require("@/assets/logo.png"),
                          alt: "Webmag Logo"
                        }
                      })
                    ]
                  ),
                  _c(
                    "b-breadcrumb",
                    { staticClass: "mb-0 ml-4" },
                    [
                      _c(
                        "b-breadcrumb-item",
                        { on: { click: _vm.closeModalOfBreadcrumbClick } },
                        [
                          _c(
                            "h2",
                            {
                              staticClass: "mb-0",
                              attrs: { id: "tooltip-button-variant-item-title" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("sliceStingPos30")(_vm.itemName)
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm.itemName.length > 30
                            ? _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: "tooltip-button-variant-item-title"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.itemName) + " ")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                        _c("h2", { staticClass: "mb-0" }, [
                          _vm._v(_vm._s(_vm.$t("webmag.preview")))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.pzClients.length > 0
                ? _c(
                    "div",
                    { staticClass: "d-flex ml-auto mr-2" },
                    [
                      _c("b-form-select", {
                        attrs: { id: "pz-clients", options: _vm.pzClients },
                        on: { input: _vm.changeSelectedClient },
                        model: {
                          value: _vm.selectedClient,
                          callback: function($$v) {
                            _vm.selectedClient = $$v
                          },
                          expression: "selectedClient"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "dark-grey-buttons",
                          attrs: { id: "phone-button" },
                          on: {
                            click: function($event) {
                              return _vm.changeDevice("phone")
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            staticClass: "top--2",
                            attrs: { icon: "phone", "aria-hidden": "true" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "dark-grey-buttons ml-2",
                          attrs: { id: "tablet-button" },
                          on: {
                            click: function($event) {
                              return _vm.changeDevice("tablet")
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            staticClass: "top--2",
                            attrs: { icon: "tablet", "aria-hidden": "true" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "dark-grey-buttons ml-2 active",
                          attrs: { id: "fullscreen-button" },
                          on: {
                            click: function($event) {
                              return _vm.changeDevice("fullscreen")
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            staticClass: "top--2",
                            attrs: { icon: "laptop", "aria-hidden": "true" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mx-4 dark-grey-buttons",
                      on: {
                        click: function($event) {
                          return ok()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "top--2",
                        attrs: {
                          icon: "arrow-left-circle-fill",
                          "aria-hidden": "true"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        { class: _vm.previewDeviceClass, attrs: { id: "ams-preview" } },
        [
          _c("div", { attrs: { id: "ams-preview-stage" } }, [
            _c("div", { attrs: { id: "ams-preview-content" } }, [
              _c("iframe", {
                attrs: {
                  width: "100%",
                  height: "100%",
                  src: _vm.iframeSrcWithToken
                }
              })
            ]),
            _c("div", {
              staticClass: "ams-preview-device",
              attrs: { id: "ams-preview-phone" }
            }),
            _c("div", {
              staticClass: "ams-preview-device",
              attrs: { id: "ams-preview-tablet" }
            }),
            _c("div", {
              staticClass: "ams-preview-device",
              attrs: { id: "ams-preview-fullscreen" }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }