<template>
  <b-modal
    id="preview-page"
    ref="preview-page"
    centered
    hide-footer
    header-class="p-0 d-flex align-items-center justify-content-between"
    ok-only
    no-close-on-backdrop
    @hide="closePreviewModal"
  >
    <div id="ams-preview" :class="previewDeviceClass">
      <div id="ams-preview-stage">
        <div id="ams-preview-content">
          <iframe width="100%" height="100%" :src="iframeSrcWithToken"></iframe>
        </div>
        <div id="ams-preview-phone" class="ams-preview-device"></div>
        <div id="ams-preview-tablet" class="ams-preview-device"></div>
        <div id="ams-preview-fullscreen" class="ams-preview-device"></div>
      </div>
    </div>
    <template #modal-header="{ ok }">
      <div class="d-flex align-items-center">
        <div style="background-color: #53575b;">
          <img
            class="m-3"
            src="@/assets/logo.png"
            alt="Webmag Logo"
            style="width: 33px;"
          />
        </div>
        <b-breadcrumb class="mb-0 ml-4">
          <b-breadcrumb-item @click="closeModalOfBreadcrumbClick">
            <h2
              id="tooltip-button-variant-item-title"
              class="mb-0"
            >
              {{ itemName | sliceStingPos30 }}
            </h2>
            <b-tooltip
              v-if="itemName.length > 30"
              target="tooltip-button-variant-item-title"
            >
              {{ itemName }}
            </b-tooltip>
          </b-breadcrumb-item>
          <b-breadcrumb-item
            active
          >
            <h2 class="mb-0">{{ $t('webmag.preview') }}</h2>
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <div v-if="pzClients.length > 0" class="d-flex ml-auto mr-2">
        <b-form-select
          id="pz-clients"
          v-model="selectedClient"
          :options="pzClients"
          @input="changeSelectedClient"
        ></b-form-select>
      </div>
      <div class="d-flex">
        <div>
          <b-button
            id="phone-button"
            @click="changeDevice('phone')"
            class="dark-grey-buttons"
          >
            <b-icon
              icon="phone"
              aria-hidden="true"
              class="top--2"
            ></b-icon>
          </b-button>
          <b-button
            id="tablet-button"
            @click="changeDevice('tablet')"
            class="dark-grey-buttons ml-2"
          >
            <b-icon
              icon="tablet"
              aria-hidden="true"
              class="top--2"
            ></b-icon>
          </b-button>
          <b-button
            id="fullscreen-button"
            @click="changeDevice('fullscreen')"
            class="dark-grey-buttons ml-2 active"
          >
            <b-icon
              icon="laptop"
              aria-hidden="true"
              class="top--2"
            ></b-icon>
          </b-button>
        </div>

        <b-button
          @click="ok()"
          class="mx-4 dark-grey-buttons"
        >
          <b-icon
            icon="arrow-left-circle-fill"
            aria-hidden="true"
            class="top--2"
          ></b-icon>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import GetPersonalizedVariableSetsItems from '@/graphQlQueries/queries/getPersonalizedVariableSetsItems';
import Auth from '@aws-amplify/auth';
import SetPreviewWindowInCache from '@/graphQlQueries/mutations/setPreviewWindowInCache';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';

export default {
  name: 'ModalPreviewPage',
  mixins: [SendPublishedDataToServer],
  props: {
    iframeSrc: {
      type: String,
      required: true,
    },
    previewActionObject: {
      type: Object,
      required: false,
    },
    itemName: {
      type: String,
      required: true,
    },
    groupDomain: {
      type: String,
      required: true,
    },
    itemPath: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    personalizedItemSetsByItemPath: null,
    deviceName: 'fullscreen',
    iframeSrcWithToken: null,
    containerDOM: null,
    contentDOM: null,
    changeInterval: null,
    stageDOM: false,
    isFullscreen: false,
    isPhone: false,
    isTablet: false,
    stage: {},
    token: null,
    transform: null,
    selectedClient: null,
    pzClients: [],
    runGetSetsByPath: true,
  }),
  async mounted() {
    await this.deleteCache();
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken();
    this.token = idToken.getJwtToken();
    // get the stuff for the preview
    this.iframeSrcWithToken = `${this.iframeSrc}?pz=""&token=${this.token}`;
    this.transform = (() => {
      if (document.documentElement.style.transform == null) {
        const vendors = ['Webkit', 'Moz', 'ms'];
        for (let i = 0; i < vendors.length; i++) {
          if (document.documentElement.style[`${vendors[i]}Transform`] !== undefined) {
            return `${vendors[i]}Transform`;
          }
        }
      }
      return 'transform';
    });
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        window.requestAnimationFrame(this.previewResize);
      }, false);
      window.requestAnimationFrame(this.previewResize);
    });
    this.$bvModal.show('preview-page');
    await this.sendPreviewActionToServer('openPreview');
  },
  computed: {
    previewDeviceClass() {
      return `preview-${this.deviceName}`;
    },
  },
  apollo: {
    personalizedItemSetsByItemPath: {
      query: GetPersonalizedVariableSetsItems,
      variables() {
        return {
          itemPath: this.itemPath,
          addValues: true,
        };
      },
      update(data) {
        // make sure that the personalized clients are only fetched once
        this.runGetSetsByPath = false;

        data.getPersonalizedVariableSetsByPath.forEach((set) => {
          if (set.values && set.values.length) {
            set.values.forEach((item) => {
              if (!this.pzClients.some((client) => client.value === item.client_key)) {
                this.pzClients.push({
                  value: item.client_key,
                  text: item.name,
                });
              }
            });
          }
        });
        if (this.pzClients.length > 0) {
          this.pzClients.unshift({
            value: null,
            text: this.$t('preview.noItemSelected'),
          });
        }
      },
      skip() {
        return !this.itemPath || this.runGetSetsByPath === false;
      },
      fetchPolicy: 'network-only',
    },
  },
  filters: {
    sliceStingPos30(value) {
      return (value.length > 30) ? `${value.slice(0, 30)}...` : value;
    },
  },
  methods: {
    deleteCache() {
      return new Promise((resolve, reject) => {
        const domain = `${this.groupDomain}/`;
        const slug_path = this.previewActionObject.parentSlugPath.slice(1);
        try {
          this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain,
              slug_path,
            },
          }).then((response) => {
            console.log('response', response);
            resolve();
          });
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    async changeSelectedClient(selectedClient) {
      await this.deleteCache();
      this.iframeSrcWithToken = `${this.iframeSrc}?pz=${selectedClient}&token=${this.token}`;
    },
    previewResize(reset) {
      let scale = 1;
      if (!this.stageDOM || reset !== undefined) {
        this.stageDOM = document.getElementById('ams-preview-stage');
        // check if the dom is already rendered
        if (!this.stageDOM) {
          return false;
        }
        this.containerDOM = document.getElementById('ams-preview');
        const classes = this.containerDOM.classList;
        this.isFullscreen = classes.contains('preview-fullscreen');
        this.isPhone = classes.contains('preview-phone');
        this.isTablet = classes.contains('preview-tablet');
        // used automatic getPropertyValue("max-width") before - but safari problems
        if (this.isFullscreen) {
          this.stage.width = document.documentElement.clientWidth;
          this.stage.height = document.documentElement.clientHeight;
        } else if (this.isPhone) {
          this.stage.width = 720;
          this.stage.height = 680;
        } else if (this.isTablet) {
          this.stage.width = 850;
          this.stage.height = 750;
        }
      }
      scale = Math.min(
        document.documentElement.clientWidth / this.stage.width,
        document.documentElement.clientHeight / this.stage.height,
      );
      if (this.isFullscreen || scale > 1) {
        scale = 1;
      }
      this.stageDOM.style[this.transform] = `scale3D(${scale},${scale},${scale})`;
      return true;
    },
    setActiveClasses(deviceName) {
      const phoneButton = document.getElementById('phone-button').classList;
      const tabletButton = document.getElementById('tablet-button').classList;
      const fullScreenButton = document.getElementById('fullscreen-button').classList;

      phoneButton.remove('active');
      tabletButton.remove('active');
      fullScreenButton.remove('active');

      // set the active class
      switch (deviceName) {
        case 'phone':
          phoneButton.add('active');
          break;
        case 'tablet':
          tabletButton.add('active');
          break;
        case 'fullscreen':
          fullScreenButton.add('active');
          break;
        default:
          console.log('no matching device');
      }
    },
    changeDevice(deviceName) {
      this.setActiveClasses(deviceName);
      const devices = document.getElementById('ams-preview').classList;

      this.deviceName = deviceName;
      this.containerDOM = document.getElementById('ams-preview');
      this.contentDOM = document.getElementById('ams-preview-content');

      switch (deviceName) {
        case 'phone':
          this.addAndRemoveClassesOfPhone(devices);
          this.doTheAnimation();
          break;
        case 'tablet':
          this.addAndRemoveClassesOfTablet(devices);
          this.doTheAnimation();
          break;
        case 'fullscreen':
          this.addAndRemoveClassesOfDesktop(devices);
          break;
        default:
          console.error('wrong device name');
      }
    },
    addAndRemoveClassesOfPhone(devices) {
      if (
        devices.contains('preview-phone')
        && !devices.contains('preview-phone-landscape')
      ) {
        devices.add('preview-landscape');
        devices.add('preview-phone-landscape');
      } else {
        devices.remove('preview-landscape');
        devices.remove('preview-phone-landscape');
      }
      devices.remove('preview-tablet-landscape');
      devices.add('preview-phone');
      devices.remove('preview-tablet');
      devices.remove('preview-fullscreen');
      devices.remove('hide-preview-3d');
      devices.add('preview-device-switch');
    },
    addAndRemoveClassesOfTablet(devices) {
      if (devices.contains('preview-tablet') && !devices.contains('preview-tablet-landscape')) {
        devices.add('preview-landscape');
        devices.add('preview-tablet-landscape');
      } else {
        devices.remove('preview-landscape');
        devices.remove('preview-tablet-landscape');
      }

      devices.remove('preview-phone-landscape');
      devices.add('preview-tablet');
      devices.remove('preview-phone');
      devices.remove('preview-fullscreen');
      devices.remove('hide-preview-3d');
      devices.add('preview-device-switch');
    },
    addAndRemoveClassesOfDesktop(devices) {
      devices.remove('preview-phone-landscape');
      devices.remove('preview-tablet-landscape');
      devices.remove('preview-landscape');

      if (!devices.contains('preview-fullscreen')) {
        devices.add('preview-fullscreen');
        devices.remove('preview-phone');
        devices.remove('preview-tablet');
        devices.remove('preview-3d');
        devices.add('hide-preview-3d');

        devices.add('preview-device-switch');
        // we put the animation here because we don't have
        // landscape and portrait in desktop mode
        this.doTheAnimation();
      }
    },
    doTheAnimation() {
      // do that for the animation
      setTimeout(() => {
        this.previewResize(true);
      }, 500);

      this.contentDOM.style.opacity = 0.001;
      window.clearInterval(this.changeInterval);
      this.changeInterval = setTimeout(() => {
        this.containerDOM.classList.remove('preview-device-switch');
        this.contentDOM.style.opacity = 1;
      }, 1100);
    },
    closeModalOfBreadcrumbClick() {
      this.$bvModal.hide('preview-page');
      this.closePreviewModal();
    },
    closePreviewModal() {
      document.body.className = document.body.className.replace('preview-modal-open', '');
      this.sendPreviewActionToServer('closePreview');
      this.$emit('preview-closed');

      this.$apollo.mutate({
        mutation: SetPreviewWindowInCache,
        variables: {
          isPreviewWindowOpen: false,
        },
      });
    },
    async sendPreviewActionToServer(previewAction) {
      try {
        await this.sendPublishedDataToServer({
          action: previewAction,
          data: this.previewActionObject,
        });
      } catch (ex) {
        console.log(ex);
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/_previewModal.scss';

#preview-page___BV_modal_body_ {
  background-color: #44484c;
  background-image: radial-gradient(circle, #696c70 10%, #44484c 80%);
}

#preview-page___BV_modal_footer_ {
  justify-content: space-between;

  > div button {
    margin-right: 8px;
  }
}

#pz-clients{
  color: #b7b7b7;
  background: #53575c url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23b7b7b7' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 0px solid #b7b7b7;
}

#preview-page {
  .modal-dialog {
    padding: 0;
    margin: 0;
    max-height: 100vh;
    height: 100vh;
    max-width: 100vw;

    .modal-header {
      background-color: #44484c;
      border-bottom: none;
      justify-content: flex-end;
      height: 65px;
      border-bottom: 1px solid #36393d;

      .active svg {
        color: $webmag-green !important;
      }
    }

    > .modal-content {
      height: 100%;

      .modal-body {
        padding: 0;
      }
    }
  }
}
</style>
